/* Style for the prompt input */
#prompt-input {
    flex-grow: 1;
    padding: 10px;
    border-radius: 5px;
    min-height: 20px;
    color: white;
    overflow: auto;
}

#prompt-input:focus {
    outline: none !important;
}

[contenteditable=true]:empty:before {
  content: attr(placeholder);
  display: block;
  color: #aaa;
}
